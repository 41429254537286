<script>
import { truncateText, priceFormat, merchantImage } from "../../../helpers.js"

export default {
    methods: {
        truncateText,
        priceFormat,
        merchantImage
    },
    data() {
        return {
            prices: '',
        }
    },
    props: {
        product: {
            type: Object,
            required: true
        }
    }
}
</script>

<template>
  <div class="mud-row">
    <div class="col-lg-4 mud-column">Product</div>
    <div class="col-lg-2 mud-column">Price</div>
    <div class="col-lg-6 mud-column">Merchant</div>
  </div>
  <div v-bind:class="{ 'mud-row': index !== product.productsDataFeedr.length - 1, 'mud-row mud-last': index === product.productsDataFeedr.length - 1 }" v-for="(price, index) in product.productsDataFeedr" v-bind:key="index">
    <div class="col-lg-4 mud-pricelist-column">{{ price.name }}</div>
    <div class="col-lg-2 mud-pricelist-column"><span class="price-featured mud-h"><p class="fs-4 price fw-600 mb-0" v-html="priceFormat(price.finalprice)"></p><p class="mud-p1 mb-0">Updated: {{ price.lastupdated }}</p></span></div>
    <div class="col-lg-6 mud-pricelist-column">
      <div class="col-lg-4">
        <img :src="merchantImage(price.merchant)" class="merchant-image" />
        <p class="mud-p1 mb-0" v-if="price.instock == 1">Stock: <span class="instock-available">Available</span></p>
        <p class="mud-p1 mb-0" v-if="price.instock == 0">Stock: <span class="instock-soldout">Sold out</span></p>
      </div>
      <div class="col-lg-4">
        <a :href="price.url" target="_blank" class="btn btn-offer">View offer <img class="offer-image" :src="require('@/assets/images/offer.svg')" /></a>
      </div>
    </div>
  </div>


  <!--div class="prices-title ps-2 pt-2 pb-2">
    <span>Prices list</span>
  </div>
  <div class="prices-columns col-lg-12 row ps-2 pt-1 pb-1">
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">Product</div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">Merchant offer</div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">Price</div>
    <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">Link</div>
  </div>
  <div>
    <aos-vue
      animation="fade-up"
      :duration="500"
      :once="true"
      v-bind:class="{'soldout': price.instock == 0, 'available': price.instock >= 1, 'nostock': price.instock == null}"
      class="row ps-1 pt-3 pb-3 price-row"
      v-for="(price, index) in product.productsDataFeedr"
      v-bind:key="index"
    >
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ps-3 column-product-name">
        {{ price.name }}
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3 ps-3">
        <img :src="merchantImage(price.merchant)" class="merchant-image" />
        <br />
        <div v-if="price.instock == 1">
          <strong class="price-list-stock">STOCK:</strong>
          <span class="instock-available">Available</span>
        </div>
        <div v-if="price.instock == 0">
          <strong class="price-list-stock">STOCK:</strong>
          <span class="instock-soldout">Sold out</span>
        </div>
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        <strong class="price column-price-offer"
          >{{ priceFormat(price.finalprice) }} €</strong
        >
        <br />
        <span class="updated-price"
          >Updated at <strong>{{ price.lastupdated }}</strong></span
        >
      </div>
      <div class="col-lg-3 col-md-3 col-sm-3 col-xs-3">
        <a :href="price.url" target="_blank" class="btn btn-success btn-offer"
          >View offer</a
        >
      </div>
    </aos-vue>
  </div-->
</template>