<script>
import { graphQLApiUrl, baseServer } from "../../helpers.js"

export default {  
    data() {
        // Definimos la URL del API GraphQL
        const url = graphQLApiUrl;

        // Definimos la consulta GraphQL que queremos hacer
        const query = `
        query {
            image_categories {
                image
                title
                link
                order
            }
        }`;

        // Definimos las opciones para la llamada fetch
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query })
        };

        // Hacemos la llamada fetch a la URL del API GraphQL con las opciones definidas
        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            this.elements = data.data.image_categories;
        })
        .catch(error => {
            // Aquí puedes manejar el error si la llamada falla
            console.error(error);
        });

        return {
            base: '',
            elements: []
        }
    },
    mounted() {
      this.base = baseServer;
    }
}
</script>

<template>
<div class="container">
    <aos-vue animation="fade-up" :once="true" :duration="300">
      <h1 class="mb-2 text-center pt-5 mt-5 mud-h">More than just lipsticks</h1>
    </aos-vue>
    <aos-vue animation="fade-up" :once="true" :duration="300">
      <p class="text-center mud-p mt-5 mb-5 mud-p-1 mud-h">Vivamus integer non suscipit taciti mus etiam at primis tempor sagittis sit, euismod libero facilisi aptent elementum felis blandit cursus gravida sociis erat ante, eleifend lectus nullam dapibus netus feugiat curae curabitur est ad. Massa curae fringilla porttitor quam sollicitudin iaculis aptent leo ligula euismod dictumst..</p>
    </aos-vue>
    <div class="row row-cols-1 row-cols-md-2 row-cols-lg-4 align-items-stretch g-4 py-5">
      <aos-vue animation="fade-up" :once="true" :duration="300" :delay="200 * (index + 1)" class="col" v-for="(element, index) in elements" v-bind:key="index">
        <a :href="element.link">
        <div class="circle-feature card card-cover h-100 overflow-hidden text-bg-dark rounded-4" :style="{ 'background-image': 'url('+base+'/'+element.image + ')', 'background-size': 'cover', 'background-position': 'center' }">
        </div>
        <div class="text-center">
          <h4 class="mt-4 mud-h">{{ element.title }}</h4>
        </div>
        </a>
      </aos-vue>
    </div>
    <div class="d-flex justify-content-center align-items-center">
      <router-link class="mud-button-first" :to="{name: 'page', params: {slug: 'categories'}}">View all categories</router-link>
    </div>
  </div>
</template>