<script>
export default {
    props: {
        list: {
            type: Array,
            required: true
        },
        class: {
            type: String,
            required: true
        }
    },
    mounted() {
        this.fetchImages()
    },
    data() {
        return {
            final_image: null
        }
    },
    methods: {
        fetchImages(index = 0) {
            if (index >= this.list.length) {
                console.log('No se encontraron imágenes válidas.');
                return;
            }

            const url = this.list[index];

            fetch(url, { redirect: 'manual' })
            .then(response => {
                console.log(response.status);
                if (response.status === 200) {
                    this.final_image = url;
                } else if (response.status === 302 || response.status === 301) {
                    this.fetchImages(index + 1)
                    return false
                } else {
                    this.fetchImages(index + 1)
                    return false
                }
            })
            .catch(error => {
                console.log(error)
                this.fetchImages(index + 1)
                return false
            });
        }
    }
}
</script>

<template>
  <img :class="this.class" :src="this.final_image" />
</template>