import { createApp } from 'vue'

//Core
import AosVue from "aos-vue";
import { createRouter, createWebHistory } from 'vue-router'

//Plugins & Styles
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap'
import './assets/css/styles.css'

//Components
import App from './App.vue'
import HomePage from './components/HomePage.vue'
import SearchPage from './components/SearchPage.vue'
import ProductPage from './components/ProductPage.vue'
import NotFound from './components/NotFound.vue'
import BrandsPage from './components/BrandsPage.vue'
import DynamicPage from './components/DynamicPage.vue'
import BlogPage from './components/BlogPage.vue'
import BlogPost from './components/BlogPost.vue'
import WishlistPage from './components/WishlistPage.vue'
import MaintenancePage from './components/MaintenancePage.vue'

function redirectToHttps() {
  if (window.location.protocol === 'http:') {
    window.location.href = 'https://' + window.location.hostname + window.location.pathname;
  }
}

// Redirigir al cargar la página
redirectToHttps();

const routes = [
    { path: '/', name:'home', component: HomePage },
    { path: '/search/', redirect: '/search/""' },
    { path: '/search/:id', name:'search', component: SearchPage },
    { path: '/product/:name/:id', name:'product', component: ProductPage },
    { path: '/:pathMatch(.*)*', name:'notfound', component: NotFound },
    { path: '/brands', name:'brands', component: BrandsPage },
    { path: '/page/:slug', name:'page', component: DynamicPage },
    { path: '/blog/category/:slug', name:'blogcategory', component: BlogPage },
    { path: '/article/:slug', name:'post', component: BlogPost },
    { path: '/blog', name:'blog', component: BlogPage },
    { path: '/wishlist', name:'wishlist', component: WishlistPage },
    { path: '/maintenance', name:'maintenance', component: MaintenancePage}
]

const router = createRouter({
    history: createWebHistory(),
    mode: 'history',
    routes,
})
router.beforeEach((to, from, next) => {
    if (process.env.VUE_APP_SKIP_COOKIE_VERIFICATION === 'true') {
      if (to.name == 'maintenance'){
        next({ name: 'home' })
      }
      else{
        next()
      }
        
    } else {
      const accessGranted = document.cookie.includes('accessGranted=true')
      if (!accessGranted && to.name !== 'maintenance') {
        next({ name: 'maintenance' })
      } else {
        next()
      }
    }
  })
  
  
AosVue.props.placement.default = 'bottom-bottom'

createApp(App).use(router).use(AosVue).mount('#app');