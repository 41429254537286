<script>
import { graphQLApiUrl, preSearch, autocompleteInit } from "../../helpers.js"

export default {  
    data() {
        // Definimos la URL del API GraphQL
        const url = graphQLApiUrl;

        // Definimos la consulta GraphQL que queremos hacer
        const query = `
        query {
            image_home(where: {value: "home"}) {
                value
                name
            }
        }`;

        // Definimos las opciones para la llamada fetch
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query })
        };

        // Hacemos la llamada fetch a la URL del API GraphQL con las opciones definidas
        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            console.log(data.data.image_home[0].value);
            this.slider_background = data.data.image_home[0].value
            this.slider_mobile = data.data.image_home[1].value
            this.slider_title = data.data.image_home[2].value
            this.slider_text = data.data.image_home[3].value
        })
        .catch(error => {
            // Aquí puedes manejar el error si la llamada falla
            console.error(error);
        });

        return {
            slider_background: '',
            slider_mobile: '',
            slider_title: '',
            slider_text: '',
            search_term: ''
        }
    },
    mounted() {
      autocompleteInit();
    },
    methods: {
      submitForm() {
          this.$router.push(`/search/${this.search_term}`);
      },
      preSearchSuggestion(q)
      {
        if (q.target.value.length > 3)
        {
          preSearch(q.target.value);
        }
      },
      preSearch
    }
}
</script>

<template>
<div id="myCarousel" class="carousel slide h-1000" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active" :style="{'background-image': 'url(' + slider_background +')', 'background-size': 'cover', 'background-position': '50%' }">
      <div class="container d-flex align-items-center">
        <div class="carousel-caption position-static d-flex flex-column align-items-center justify-content-center">
          <h1 class="mud-slider-title">{{ slider_title }}</h1>
          <p class="mud-slider-subtitle">{{ slider_text }}</p>
          <form @submit.prevent="submitForm" class="col-lg-7 mt-3 ms-5 me-5 searchbar" role="search">
              <div class="search-container">
              <input id="autocomplete" v-on:input="preSearchSuggestion" v-model="search_term" autocomplete="off" type="search" name="search" class="form-control form-control-dark text-bg-white search-field-slider" placeholder="Find products or brands" aria-label="Search">
              <span class="search-icon">
              <svg width="20px" height="20px" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M10 1C5.02944 1 1 5.02944 1 10C1 14.9706 5.02944 19 10 19C12.125 19 14.078 18.2635 15.6177 17.0319L20.2929 21.7071C20.6834 22.0976 21.3166 22.0976 21.7071 21.7071C22.0976 21.3166 22.0976 20.6834 21.7071 20.2929L17.0319 15.6177C18.2635 14.078 19 12.125 19 10C19 5.02944 14.9706 1 10 1ZM3 10C3 6.13401 6.13401 3 10 3C13.866 3 17 6.13401 17 10C17 13.866 13.866 17 10 17C6.13401 17 3 13.866 3 10Z" fill="#000000"/>
              </svg>
              </span>
              </div>
          </form>
        </div>
        <div class="slider-overlay"></div>
      </div>
    </div>
  </div>
</div>
</template>