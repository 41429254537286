<script>
import { graphQLApiUrl, truncateText, HTMLparse, urlfriendly,updatewishlist,addToWishlist,inWishlist,priceFormat,loading} from "../../helpers.js"
import ImageMud from './product/ImageMud.vue'

export default {  
    components: {
        ImageMud
    },
    methods: {
        truncateText,
        HTMLparse,
        urlfriendly,
        updatewishlist,
        addToWishlist,
        inWishlist,
        priceFormat
    },
    updated(){
        loading();
    },
    data() {
        // Definimos la URL del API GraphQL
        const url = graphQLApiUrl;

        // Definimos la consulta GraphQL que queremos hacer
        const featured_query = `
        query {
            products(filter: {
                pagination: {
                    page: 1,
                    itemsPerPage: 5
                },
                featured: 1
                }) 
                {
                data {
                  _id
                  name
                  description
                  currency
                  price
                  _brand {
                    name
                  }
                  productsDataFeedr {
                    baseprice
                  }
                  ean
                  thumbnail
                  images
                }
                pageInfo {
                  page
                  total
                  totalPages
                }
                brandsFilter {
                  name
                  cant
                  _id
                }
            }
        }`;

        const top_query = `
        query {
            products(filter: {
                pagination: {
                    page: 1,
                    itemsPerPage: 5
                },
                top: 1
                }) 
                {
                data {
                  _id
                  name
                  description
                  currency
                  price
                  _brand {
                    name
                  }
                  ean
                  thumbnail
                  images
                }
                pageInfo {
                  page
                  total
                  totalPages
                }
                brandsFilter {
                  name
                  cant
                  _id
                }
            }
        }`;

        // Definimos las opciones para la llamada fetch
        const featured_options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query: featured_query })
        };

        const top_options = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ query: top_query })
            };

        // Hacemos la llamada fetch a la URL del API GraphQL con las opciones definidas
        fetch(url, featured_options).then(response => response.json()).then(data => {
            this.featured = data.data.products.data;
        })
        .catch(error => {
            // Aquí puedes manejar el error si la llamada falla
            console.error(error);
        });

        // Hacemos la llamada fetch a la URL del API GraphQL con las opciones definidas
        fetch(url, top_options).then(response => response.json()).then(data => {
            this.top = data.data.products.data;
        })
        .catch(error => {
            // Aquí puedes manejar el error si la llamada falla
            console.error(error);
        });

        return {
            featured: [],
            top: []
        }
    }
}
</script>

<template>
<div class="container">
    <h1 class="text-center pt-5 mt-4 mud-h">Featured products</h1>
    <div class="product-grid row py-5 row-cols-xs-2 row-cols-lg-5">
        <aos-vue animation="zoom-in" :once="true" :duration="300" :delay="100 * index" class="position-relative feature col product-div" v-for="(element, index) in featured" v-bind:key="index">
            <a href="#" @click.prevent="updatewishlist(element);">
                <svg class="hearth" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path :class="{ 'filled-heart': inWishlist(element) }" :fill="inWishlist(element) ? '#948E8E' : 'none'" d="M21.3 3.8a5.6 5.6 0 00-8.1 0L12 5l-1.2-1.2a5.6 5.6 0 00-8.1 0 6.2 6.2 0 000 8.5l1.2 1.2L12 22l8.1-8.5 1.2-1.2a6.2 6.2 0 000-8.5z"></path>
                </svg>
            </a>
            <div class="text-center mb-3">
            <router-link :to="{ name: 'product', params: { name: this.urlfriendly(element.name), id: element._id } }">
                <image-mud :class="'image-grid-home'" :list="element.images" />
            </router-link>
            </div>
            <h3 class="fs-6 fw-bold product-title mud-h">{{ element._brand.name }}</h3>
            <span class="brand mud-h">{{ truncateText(element.name,45) }}</span>
            <!--p>{{ HTMLparse(truncateText(element.description,80)) }}</p-->
            <div class="text-start pt-3 pb-3">
                <span class="price-featured mud-h">From<br><p class="fs-4 price fw-600" v-html="priceFormat(element.price)"></p></span>
            </div>
        </aos-vue>
    </div>
    <h1 class="text-center pt-5 mt-4 mud-h">Best seller</h1>
    <div class="product-grid row py-5 row-cols-xs-2 row-cols-lg-5 mb-5">
        <aos-vue animation="zoom-in" :once="true" :duration="300" :delay="100 * index" class="position-relative feature col product-div" v-for="(element, index) in top" v-bind:key="index">
            <a href="#" @click.prevent="updatewishlist(element);">
                <svg class="hearth" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path :class="{ 'filled-heart': inWishlist(element) }" :fill="inWishlist(element) ? '#948E8E' : 'none'" d="M21.3 3.8a5.6 5.6 0 00-8.1 0L12 5l-1.2-1.2a5.6 5.6 0 00-8.1 0 6.2 6.2 0 000 8.5l1.2 1.2L12 22l8.1-8.5 1.2-1.2a6.2 6.2 0 000-8.5z"></path>
                </svg>
            </a>
            <div class="text-center mb-3">
            <router-link :to="{ name: 'product', params: { name: this.urlfriendly(element.name), id: element._id } }">
                <image-mud :class="'image-grid-home'" :list="element.images" />
            </router-link>
            </div>
            <h3 class="fs-6 fw-bold product-title mud-h">{{ element._brand.name }}</h3>
            <span class="brand mud-h">{{ truncateText(element.name,45) }}</span>
            <!--p>{{ HTMLparse(truncateText(element.description,80)) }}</p-->
            <div class="text-start pt-3 pb-3">
                <span class="price-featured mud-h">From<br><p class="fs-4 price fw-600" v-html="priceFormat(element.price)"></p></span>
            </div>
        </aos-vue>
    </div>
</div>
</template>