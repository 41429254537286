<script>
export default {
}
</script>

<template>
<div class="d-flex flex-row justify-content-center align-items-center p-0 footer-supbar">
    <div class="container">
        <div class="row">
            <div class="footer-redblock col-lg-4 col-12 d-flex align-items-center mt-3 m-lg-0 justify-content-center justify-content-lg-start">
                <img :src="require('@/assets/images/footer_1.svg')" class="float-start" alt="search" />
                <p class="ms-3 p-0 mb-0"><strong>Search</strong> Join us and get premium discounts</p>
            </div>
            <div class="footer-redblock col-lg-4 col-12 d-flex align-items-center mt-3 m-lg-0 justify-content-center justify-content-lg-start">
                <img :src="require('@/assets/images/footer_3.svg')" alt="search" />
                <p class="ms-3 p-0 mb-0"><strong>Compare</strong> 20% OFF selected products in your first order</p>
            </div>
            <div class="footer-redblock col-lg-4 col-12 d-flex align-items-center mt-3 m-lg-0 mb-3 justify-content-center justify-content-lg-start">
                <img :src="require('@/assets/images/footer_2.svg')" alt="search" />
                <p class="ms-3 p-0 mb-0"><strong>Save</strong> Free delivery over 30 €</p>
            </div>
        </div>
    </div>
</div>
<div class="footer">
    <div class="container footer-mud-first">
        <div class="row mt-5 mb-5">
            <div data-aos="fade-right" class="mb-5 col-lg-4 col-xs-12 col-md-12 mb-3 aos-init aos-animate  text-center text-lg-start">
                <div>
                <svg width="150" height="39" viewBox="0 0 150 39" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M104.215 38.2545H118.399C123.833 38.0632 131.665 33.8665 138.642 26.8891C148.286 17.2458 152.635 5.94419 148.349 1.65825C147.252 0.561257 145.709 0.03827 143.847 0.0127585L11.7863 0C5.28089 0 0 8.57187 0 19.1336C0 29.6954 5.28089 38.2673 11.7863 38.2673H104.215" fill="#FF4455"/>
                <path d="M17.9139 11.7399H19.8379L24.3621 22.7902H24.5181L29.0422 11.7399H30.9662V25.0523H29.4582V14.938H29.3282L25.1681 25.0523H23.712L19.5519 14.938H19.4219V25.0523H17.9139V11.7399Z" fill="white"/>
                <path d="M36.9285 25.2863C36.2959 25.2863 35.7217 25.1671 35.206 24.9288C34.6903 24.6861 34.2808 24.3373 33.9775 23.8823C33.6741 23.4229 33.5224 22.8682 33.5224 22.2182C33.5224 21.6462 33.6351 21.1825 33.8605 20.8272C34.0858 20.4675 34.387 20.1858 34.764 19.9822C35.141 19.7785 35.557 19.6268 36.012 19.5271C36.4714 19.4231 36.9329 19.3408 37.3966 19.2801C38.0032 19.2021 38.4951 19.1436 38.8721 19.1046C39.2534 19.0613 39.5308 18.9898 39.7041 18.8901C39.8818 18.7905 39.9706 18.6171 39.9706 18.3701V18.3181C39.9706 17.6768 39.7951 17.1784 39.4441 16.8231C39.0974 16.4677 38.5709 16.2901 37.8646 16.2901C37.1322 16.2901 36.558 16.4504 36.142 16.7711C35.726 17.0917 35.4335 17.4341 35.2645 17.7981L33.8085 17.2781C34.0685 16.6714 34.4151 16.1991 34.8485 15.861C35.2862 15.5187 35.7628 15.2804 36.2785 15.146C36.7985 15.0074 37.3099 14.938 37.8126 14.938C38.1332 14.938 38.5016 14.977 38.9176 15.055C39.3379 15.1287 39.7431 15.2825 40.1331 15.5165C40.5275 15.7505 40.8547 16.1037 41.1147 16.5761C41.3747 17.0484 41.5047 17.6811 41.5047 18.4741V25.0523H39.9706V23.7003H39.8926C39.7886 23.9169 39.6153 24.1488 39.3726 24.3958C39.1299 24.6428 38.8071 24.853 38.4041 25.0263C38.0011 25.1996 37.5092 25.2863 36.9285 25.2863ZM37.1625 23.9083C37.7692 23.9083 38.2806 23.7891 38.6966 23.5508C39.1169 23.3124 39.4333 23.0047 39.6456 22.6277C39.8623 22.2507 39.9706 21.8542 39.9706 21.4382V20.0342C39.9056 20.1122 39.7626 20.1837 39.5416 20.2487C39.3249 20.3093 39.0736 20.3635 38.7876 20.4112C38.5059 20.4545 38.2307 20.4935 37.9621 20.5282C37.6977 20.5585 37.4832 20.5845 37.3186 20.6062C36.9199 20.6582 36.5472 20.7427 36.2005 20.8597C35.8582 20.9724 35.5808 21.1435 35.3685 21.3732C35.1605 21.5985 35.0565 21.9062 35.0565 22.2962C35.0565 22.8292 35.2537 23.2323 35.648 23.5053C36.0467 23.7739 36.5515 23.9083 37.1625 23.9083Z" fill="white"/>
                <path d="M45.7347 21.4122L45.7087 19.5141H46.0207L50.3888 15.068H52.2868L47.6327 19.7742H47.5027L45.7347 21.4122ZM44.3046 25.0523V11.7399H45.8387V25.0523H44.3046ZM50.6488 25.0523L46.7487 20.1122L47.8407 19.0461L52.5989 25.0523H50.6488Z" fill="white"/>
                <path d="M58.0363 25.2603C57.0742 25.2603 56.2444 25.048 55.5467 24.6233C54.8533 24.1943 54.3182 23.5963 53.9411 22.8292C53.5685 22.0579 53.3821 21.1609 53.3821 20.1382C53.3821 19.1155 53.5685 18.2141 53.9411 17.4341C54.3182 16.6497 54.8425 16.0387 55.5142 15.601C56.1902 15.159 56.9789 14.938 57.8803 14.938C58.4003 14.938 58.9138 15.0247 59.4208 15.198C59.9278 15.3714 60.3893 15.653 60.8053 16.0431C61.2214 16.4287 61.5529 16.9401 61.7999 17.5771C62.0469 18.2141 62.1704 18.9985 62.1704 19.9302V20.5802H54.4742V19.2541H60.6103C60.6103 18.6908 60.4977 18.1881 60.2723 17.7461C60.0513 17.3041 59.735 16.9552 59.3233 16.6996C58.916 16.4439 58.4349 16.3161 57.8803 16.3161C57.2692 16.3161 56.7406 16.4677 56.2942 16.7711C55.8522 17.0701 55.512 17.4601 55.2737 17.9411C55.0353 18.4221 54.9162 18.9378 54.9162 19.4881V20.3722C54.9162 21.1262 55.0462 21.7654 55.3062 22.2897C55.5705 22.8097 55.9367 23.2063 56.4047 23.4793C56.8727 23.7479 57.4166 23.8823 58.0363 23.8823C58.4393 23.8823 58.8033 23.8259 59.1283 23.7133C59.4576 23.5963 59.7415 23.4229 59.9798 23.1933C60.2182 22.9592 60.4023 22.6689 60.5323 22.3222L62.0144 22.7382C61.8584 23.2409 61.5962 23.6829 61.2279 24.0643C60.8595 24.4413 60.4045 24.736 59.8628 24.9483C59.3211 25.1563 58.7123 25.2603 58.0363 25.2603Z" fill="white"/>
                <path d="M70.7961 20.9702V15.068H72.3302V25.0523H70.7961V23.3623H70.6921C70.4581 23.8693 70.0941 24.3004 69.6001 24.6558C69.1061 25.0068 68.4821 25.1823 67.728 25.1823C67.104 25.1823 66.5493 25.0458 66.064 24.7728C65.5786 24.4955 65.1973 24.0794 64.92 23.5248C64.6426 22.9657 64.5039 22.2616 64.5039 21.4122V15.068H66.038V21.3082C66.038 22.0362 66.2417 22.6169 66.649 23.0502C67.0607 23.4836 67.585 23.7003 68.222 23.7003C68.6034 23.7003 68.9912 23.6028 69.3856 23.4078C69.7843 23.2128 70.1179 22.9137 70.3866 22.5107C70.6596 22.1077 70.7961 21.5942 70.7961 20.9702Z" fill="white"/>
                <path d="M75.1399 28.7964V15.068H76.6219V16.6541H76.8039C76.9166 16.4807 77.0726 16.2597 77.2719 15.991C77.4756 15.718 77.7659 15.4754 78.1429 15.263C78.5243 15.0464 79.04 14.938 79.69 14.938C80.5307 14.938 81.2717 15.1482 81.9131 15.5685C82.5544 15.9889 83.0549 16.5847 83.4146 17.3561C83.7743 18.1274 83.9541 19.0375 83.9541 20.0862C83.9541 21.1435 83.7743 22.0601 83.4146 22.8357C83.0549 23.6071 82.5566 24.2051 81.9196 24.6298C81.2825 25.0501 80.548 25.2603 79.716 25.2603C79.0746 25.2603 78.5611 25.1541 78.1755 24.9418C77.7898 24.7251 77.4929 24.4803 77.2849 24.2073C77.0769 23.9299 76.9166 23.7003 76.8039 23.5183H76.6739V28.7964H75.1399ZM76.6479 20.0602C76.6479 20.8142 76.7584 21.4794 76.9794 22.0557C77.2004 22.6277 77.5233 23.0762 77.9479 23.4013C78.3726 23.7219 78.8926 23.8823 79.508 23.8823C80.1493 23.8823 80.6845 23.7133 81.1135 23.3753C81.5469 23.0329 81.8719 22.5736 82.0886 21.9972C82.3096 21.4165 82.4201 20.7708 82.4201 20.0602C82.4201 19.3581 82.3117 18.7255 82.0951 18.1621C81.8827 17.5944 81.5599 17.1459 81.1265 16.8166C80.6975 16.4829 80.158 16.3161 79.508 16.3161C78.884 16.3161 78.3596 16.4742 77.9349 16.7906C77.5103 17.1026 77.1896 17.5403 76.9729 18.1036C76.7562 18.6626 76.6479 19.3148 76.6479 20.0602Z" fill="white"/>
                <path d="M90.6103 25.0523H86.5022V11.7399H90.7923C92.0837 11.7399 93.1887 12.0064 94.1074 12.5395C95.0261 13.0681 95.7303 13.8287 96.22 14.821C96.7096 15.809 96.9545 16.9921 96.9545 18.3701C96.9545 19.7568 96.7075 20.9507 96.2135 21.9517C95.7195 22.9484 95.0001 23.7154 94.0554 24.2528C93.1107 24.7858 91.9623 25.0523 90.6103 25.0523ZM88.1142 23.6223H90.5063C91.607 23.6223 92.5192 23.4099 93.2429 22.9852C93.9666 22.5606 94.5061 21.9561 94.8614 21.1717C95.2168 20.3873 95.3944 19.4535 95.3944 18.3701C95.3944 17.2954 95.2189 16.3702 94.8679 15.5945C94.5169 14.8145 93.9926 14.2165 93.2949 13.8005C92.5972 13.3801 91.7283 13.17 90.6883 13.17H88.1142V23.6223Z" fill="white"/>
                <path d="M103.637 25.2603C102.675 25.2603 101.845 25.048 101.147 24.6233C100.454 24.1943 99.9186 23.5963 99.5416 22.8292C99.1689 22.0579 98.9825 21.1609 98.9825 20.1382C98.9825 19.1155 99.1689 18.2141 99.5416 17.4341C99.9186 16.6497 100.443 16.0387 101.115 15.601C101.791 15.159 102.579 14.938 103.481 14.938C104.001 14.938 104.514 15.0247 105.021 15.198C105.528 15.3714 105.99 15.653 106.406 16.0431C106.822 16.4287 107.153 16.9401 107.4 17.5771C107.647 18.2141 107.771 18.9985 107.771 19.9302V20.5802H100.075V19.2541H106.211C106.211 18.6908 106.098 18.1881 105.873 17.7461C105.652 17.3041 105.335 16.9552 104.924 16.6996C104.516 16.4439 104.035 16.3161 103.481 16.3161C102.87 16.3161 102.341 16.4677 101.895 16.7711C101.453 17.0701 101.112 17.4601 100.874 17.9411C100.636 18.4221 100.517 18.9378 100.517 19.4881V20.3722C100.517 21.1262 100.647 21.7654 100.907 22.2897C101.171 22.8097 101.537 23.2063 102.005 23.4793C102.473 23.7479 103.017 23.8823 103.637 23.8823C104.04 23.8823 104.404 23.8259 104.729 23.7133C105.058 23.5963 105.342 23.4229 105.58 23.1933C105.819 22.9592 106.003 22.6689 106.133 22.3222L107.615 22.7382C107.459 23.2409 107.197 23.6829 106.828 24.0643C106.46 24.4413 106.005 24.736 105.463 24.9483C104.922 25.1563 104.313 25.2603 103.637 25.2603Z" fill="white"/>
                <path d="M113.042 25.2863C112.41 25.2863 111.836 25.1671 111.32 24.9288C110.804 24.6861 110.395 24.3373 110.091 23.8823C109.788 23.4229 109.636 22.8682 109.636 22.2182C109.636 21.6462 109.749 21.1825 109.974 20.8272C110.2 20.4675 110.501 20.1858 110.878 19.9822C111.255 19.7785 111.671 19.6268 112.126 19.5271C112.585 19.4231 113.047 19.3408 113.51 19.2801C114.117 19.2021 114.609 19.1436 114.986 19.1046C115.367 19.0613 115.645 18.9898 115.818 18.8901C115.996 18.7905 116.085 18.6171 116.085 18.3701V18.3181C116.085 17.6768 115.909 17.1784 115.558 16.8231C115.211 16.4677 114.685 16.2901 113.978 16.2901C113.246 16.2901 112.672 16.4504 112.256 16.7711C111.84 17.0917 111.547 17.4341 111.378 17.7981L109.922 17.2781C110.182 16.6714 110.529 16.1991 110.962 15.861C111.4 15.5187 111.877 15.2804 112.392 15.146C112.912 15.0074 113.424 14.938 113.926 14.938C114.247 14.938 114.615 14.977 115.031 15.055C115.452 15.1287 115.857 15.2825 116.247 15.5165C116.641 15.7505 116.969 16.1037 117.229 16.5761C117.489 17.0484 117.619 17.6811 117.619 18.4741V25.0523H116.085V23.7003H116.007C115.903 23.9169 115.729 24.1488 115.487 24.3958C115.244 24.6428 114.921 24.853 114.518 25.0263C114.115 25.1996 113.623 25.2863 113.042 25.2863ZM113.276 23.9083C113.883 23.9083 114.394 23.7891 114.81 23.5508C115.231 23.3124 115.547 23.0047 115.76 22.6277C115.976 22.2507 116.085 21.8542 116.085 21.4382V20.0342C116.02 20.1122 115.877 20.1837 115.656 20.2487C115.439 20.3093 115.188 20.3635 114.901 20.4112C114.62 20.4545 114.345 20.4935 114.076 20.5282C113.812 20.5585 113.597 20.5845 113.432 20.6062C113.034 20.6582 112.661 20.7427 112.314 20.8597C111.972 20.9724 111.695 21.1435 111.482 21.3732C111.274 21.5985 111.17 21.9062 111.17 22.2962C111.17 22.8292 111.368 23.2323 111.762 23.5053C112.161 23.7739 112.665 23.9083 113.276 23.9083Z" fill="white"/>
                <path d="M121.953 11.7399V25.0523H120.419V11.7399H121.953Z" fill="white"/>
                <path d="M127.31 19.2281L127.414 16.3681L124.996 17.9021L124.372 16.8101L126.92 15.484L124.372 14.158L124.996 13.066L127.414 14.6L127.31 11.7399H128.558L128.454 14.6L130.872 13.066L131.496 14.158L128.948 15.484L131.496 16.8101L130.872 17.9021L128.454 16.3681L128.558 19.2281H127.31Z" fill="white"/>
                </svg>
                </div>
                <p class="pt-3 mud-logo-subtext mud-h">Your go-to marketplace for beautiful savings.</p>
            </div>

            <div class="col-xs-12 pe-xs-5 col-md-12 mb-3 col-lg-2 footer-menu text-center text-lg-start mud-h">
                <strong class="footer-title">Shopping info</strong>
                <br>
                <router-link :to="{name: 'brands'}" class="">Brands</router-link ><br>
                <router-link class="" :to="{name: 'page', params: {slug: 'categories'}}">Categories</router-link><br>
                <router-link class="" :to="{name: 'blog'}">News</router-link><br>
                <router-link class="" :to="{name: 'page', params: {slug: 'promotions'}}">Promotions</router-link><br>
                <router-link class="" :to="{name: 'page', params: {slug: 'contact-us'}}">Contact us</router-link><br>
            </div>

            <div class="col-xs-12 pe-xs-5 col-md-12 mb-3 col-lg-2 footer-menu mb-5 text-center text-lg-start mud-h">
                <strong class="footer-title">Best categories</strong>
                <br>
                <router-link class="" :to="{name: 'page', params: {slug: 'privacy-policy'}}">Perfume</router-link><br>
                <router-link class="" :to="{name: 'page', params: {slug: 'legal-notice'}}">Lipsticks</router-link><br>
                <router-link class="" :to="{name: 'page', params: {slug: 'legal-notice'}}">Deodorant</router-link><br>
                <router-link class="" :to="{name: 'page', params: {slug: 'legal-notice'}}">Nail Polish</router-link>
            </div>

            <div class="col-xs-12 pe-xs-5 col-md-12 mb-3 col-lg-4 footer-menu text-center text-lg-start mud-h">
                <strong class="footer-title">Important information</strong>
                <br>
                <router-link class="" :to="{name: 'page', params: {slug: 'privacy-policy'}}">Privacy policy</router-link><br>
                <router-link class="" :to="{name: 'page', params: {slug: 'legal-notice'}}">General conditions</router-link><br>
            </div>
        </div>
    </div>
    <div class="d-flex flex-column flex-sm-row justify-content-between py-3 footer-legales">
        <div class="container">
            <div class="row mt-3 mb-3">
                <div class="col-lg-6 col-md-12 text-center text-lg-start">
                    <p class="mb-0 mud-powered-text">Contact email: <strong>contact@makeup-deal.com</strong></p>
                </div>
                <div class="col-lg-6 col-md-12 text-center text-lg-end mt-lg-0 mt-2">
                    <p class="mb-0 mud-powered-text">© 2023 MakeupDeal* - All rights reserved</p>
                </div>
            </div>
        </div>
    </div>
</div>
</template>