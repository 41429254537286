<script>
import MainHeader from './elements/MainHeader.vue'
import FooterSection from './elements/FooterSection.vue'
import { loading } from "../helpers.js"

export default {

    components: {
        MainHeader,
        FooterSection
    },
    mounted(){
      loading();
    },
}
</script>

<template>
  <main-header />
  <div>Make Up Deal - Site Not Found</div>
  <footer-section />
</template>
