<script>
export default {  
}
</script>

<template>
    <div class="text-center mt-5 pt-5">
        <a rel="sponsored" href="https://www.awin1.com/cread.php?s=3230102&v=33007&q=444855&r=1089575">
            <img src="https://www.awin1.com/cshow.php?s=3230102&v=33007&q=444855&r=1089575" border="0">
        </a>
    </div>
</template>