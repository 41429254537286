<template>
    <div>
      <div v-if="!hasAccess" class="text-center w-100 pt-5 mt-5">
        <h1 class="mud-slider-title mud-h" style="color:black">MakeupDeal *</h1>
        <h1 class="mud-h mb-5">Coming soon</h1>
        <form @submit.prevent="submitPassword">
          <p class="mud-h mb-5">Insert your password for view development site</p>
          <input class="brand-finder" type="password" v-model="password" placeholder="Enter Password" />
          <br><br><br>
          <button type="submit" class="mud-button-first">Submit</button>
        </form>
      </div>
      <div v-else>
        <h1>Welcome to the Rest of the Site!</h1>
      </div>
    </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        password: '',
        hasAccess: false
      }
    },
    methods: {
      submitPassword() {
        const correctPassword = 'makeupdeal32'
        
        if (this.password === correctPassword) {
          this.setCookie('accessGranted', 'true')
          this.hasAccess = true
          this.$router.push('/')
        } else {
          alert('Incorrect password!')
        }
      },
      setCookie(name, value) {
        const expires = new Date()
        expires.setTime(expires.getTime() + 30 * 24 * 60 * 60 * 1000) // Set expiration to 30 days
        
        document.cookie = `${name}=${value};expires=${expires.toUTCString()};path=/`
      },
      getCookie(name) {
        const cookieName = `${name}=`
        const cookieArray = document.cookie.split(';')
        
        for (let i = 0; i < cookieArray.length; i++) {
          let cookie = cookieArray[i]
          
          while (cookie.charAt(0) === ' ') {
            cookie = cookie.substring(1)
          }
          
          if (cookie.indexOf(cookieName) === 0) {
            return cookie.substring(cookieName.length, cookie.length)
          }
        }
        
        return ''
      }
    },
    created() {
    const accessGranted = this.getCookie('accessGranted')
    this.hasAccess = accessGranted === 'true'
    
    if (this.hasAccess) {
      this.$router.push('/')
    }
  }
  }
</script>
  