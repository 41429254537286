<script>
import { graphQLApiUrl  } from "../../../helpers.js"

export default {
  methods: {
        getDescription() {
            const url = graphQLApiUrl

            const query = `
            query {
                description(_id: "`+this.id+`") 
                {
                    description
                }
            }`;
    
            const featured_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
            },
                body: JSON.stringify({ query: query })
            };
    
            fetch(url, featured_options).then(response => response.json()).then(data => {
                let tablestring = data.data.description.description;
                tablestring = tablestring.replace(/[\r\n]+/gm,'').replace(/(<([^>]+)>)/gi, "");
                
                try {
                    this.text = false;
                    this.description = JSON.parse(tablestring);
                    console.log('La cadena es un JSON válido');
                } catch (e) {
                    this.text = true;
                    this.description = tablestring;
                    console.log('La cadena no es un JSON válido');
                }

                this.$refs.loader.style.display='none';
            })
            .catch(error => {
                console.error(error);
            });
        }
    },
    mounted() {
        this.id = this.$route.params.id;
        setTimeout(() => {
            this.getDescription();
        }, "3000");
    },
    data() {
        return {
            text: true,
            description: '',
            id: '',
        }
    }
}
</script>

<template>
  <div class="loader text-center" v-show="true" ref="loader">
    <img :src="require('@/assets/images/loader.png')" alt="Loader image" id="loader" class="mt-5 mb-5" />
    </div>
    <div class="p-4 table-description" v-if="description && !text">
        <table>
            <thead>
                <tr>
                <th>Detail</th>
                <th>Description</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="element in description" v-bind:key="element">
                <td width="30%" v-for="(value, key) in element"  v-bind:key="key">{{ key }}</td>
                <td v-for="(value, key) in element" v-bind:key="key">{{ value }}</td>
                </tr>
            </tbody>
        </table>
    </div>
    <div class="p-4 table-description mud-p2" v-if="text">
    {{description}}
    </div>
</template>