<script>
import { graphQLApiUrl, priceFormat, scrolltop, loading, baseServer } from "../helpers.js"
import MainHeader from './elements/MainHeader.vue'
import FooterSection from './elements/FooterSection.vue'

export default {
    components: {
        MainHeader,
        FooterSection
    },
    methods: {
        scrolltop,
        priceFormat,
        initBlog() {
            const url = graphQLApiUrl

            const query = `
            query {
                blogcategories {
                    name
                    slug
                }
            }`;

            const featured_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
            },
                body: JSON.stringify({ query: query })
            };

            fetch(url, featured_options).then(response => response.json()).then(data => {
                if (data.data.blogcategories[0])
                {
                    this.categories = data.data.blogcategories;
                    console.log(this.categories);
                }
                else
                {
                    //document.location = '/404';
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        getNews() {
            const url = graphQLApiUrl

            const query = `
            query {
                bpost(category: `+this.category+` ) {
                    title
                    slug
                    description
                    published
                    article
                    category
                    _category {
                        name
                    }
                    author
                    thumbnail
                    status
                }
            }`;

            const featured_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
            },
                body: JSON.stringify({ query: query })
            };

            fetch(url, featured_options).then(response => response.json()).then(data => {
                if (data.data.bpost[0])
                {
                    this.news = data.data.bpost;
                    console.log(this.categories);
                }
                else
                {
                    //document.location = '/404';
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        formatDate(dateString) {
            const date = new Date(parseInt(dateString));
            const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
            return date.toLocaleDateString(undefined, options);
        }
    },
    data() {
        return {
            categories: null,
            news: null,
            category: 'null',
            base: baseServer
        }
    },
    mounted() {
        if(this.$route.params.slug) {
            this.category = '"' + this.$route.params.slug + '"'
        }
        scrolltop();
        document.title = 'MakeupDeal * - Blog';
        this.initBlog();
        this.getNews();
        loading();
    },
    computed: {
        filteredCategories() {
            if(this.categories != null)
            {
                return this.categories.filter(category => category.name !== 'Page')
            }
            else
            {
                return []
            }
        }
    }    
}
</script>

<template>
  <main-header />
  <div class="container mt-5 pt-5">
    <h1 class="mb-5 mud-h text-center">Explore our Blog</h1>
    <div class="row">
        <div class="col-lg-9">
            <div class="row blog-cell pt-3 mb-4" v-for="(post, index) in news" v-bind:key="index">
                <div class="col-lg-3">
                    <img class="blog-image mb-3" :src="this.base + '/' + post.thumbnail">
                </div>
                <div class="col-lg-9">
                    <h2 class="fs-6 wishlist-title mb-3 blog-title">{{ post.title }}<br><small class="mud-p2">Published at {{ formatDate(post.published) }}</small></h2>
                    <br>
                    <p class="mud-p2">{{ post.description }}</p>
                    <router-link :to="{name: 'post', params: {slug: post.slug}}" class="mud-button-four me-2 mt-4 blog-readmore">Read more</router-link>
                </div>
            </div>
        </div>
        <div class="col-lg-3">
            <h3 class="fs-6 wishlist-title mb-3">Categories</h3>
            <div>
                <ul class="mud-stylelist">
                    <li class="mud-p3" v-for="(category, index) in filteredCategories" v-bind:key="index">
                        <router-link class="nav-link" :to="{name: 'blogcategory', params: {slug: category.slug}}">{{ category.name }}</router-link>
                    </li>
                </ul>
            </div>
        </div>
    </div>
  </div>
  <footer-section />
</template>