<script>
import { truncateText, HTMLparse, urlfriendly, meiliSearch, meiliSearchBrands,rangeSlider } from "../../helpers.js"

export default {
    methods: {
        truncateText,
        HTMLparse,
        urlfriendly,
        meiliSearch,
        radioChange(min,max){
            this.price_min=min
            this.price_max=max
            this.currentPage=1
            this.ApplyPriceFilter()
        },
        ApplyPriceFilter(){
            this.$emit("Price-Filter-Change",{price_min :this.price_min,price_max: this.price_max})
            this.getBrands()
        },
        radioSortChange(order){
            if(order != ""){
                this.sort=["price:"+order];
            }
            else{
                this.sort=[];
            }
            this.ApplySortFilter();
        },
        ApplySortFilter(){
            this.$emit("Sort-Change",this.sort)
        },
        handleSliderValueChanged(event) {
            const { from, to } = event.detail;
            this.price_min = from;
            this.price_max = to;
            // Call the method or perform necessary actions with the updated values
          },
          updateSelectedBrands(event) {
            const brand = event.target.value;
            const isChecked = event.target.checked;
            this.localSelectedBrands = this.selectedBrands;

            let newSelectedBrands;
            if (isChecked) {
              newSelectedBrands = [...this.localSelectedBrands, brand]; // Create a new array instance
            } else {
              const index = this.localSelectedBrands.indexOf(brand);
              if (index !== -1) {
                newSelectedBrands = [...this.localSelectedBrands]; // Create a new array instance
                newSelectedBrands.splice(index, 1);
              }
            }

            this.localSelectedBrands = newSelectedBrands; // Update the selectedBrands property with the new array
            this.$emit("selected-brands-updated", this.localSelectedBrands);
        },
        getBrands() {
            const self = this;
            let filter = 'price > ' + this.price_min + ' AND price < ' + this.price_max;

            meiliSearchBrands({
                q: this.search,
                facets: this.facets,
                hitsPerPage: 12,
                filter: filter,
                page: this.currentPage
            }).then(function(list) {
                self.brands = list;
                self.filteredbrands= self.brands
            });
        },
        filter_brands() {
            const filtered = Object.entries(this.brands).filter(([brand]) => {
                return brand.toLowerCase().includes(this.searchKeyword.toLowerCase());
              });
              this.filteredbrands = Object.fromEntries(filtered);
          },
        showFilters() {
          let filter = document.getElementsByClassName('filter');

          Array.prototype.forEach.call(filter, function(el) {
            if(el.style.display != 'block')
            {
              el.style.display = 'block'
            }
            else
            {
              el.style.display = 'none'
            }
          });

        }
    },
    watch: {
        search() {
            if(!this.comingFromBrands){
                this.getBrands()
            }
        },
    },
    data() {
        return {
            brands: [],
            localSelectedBrands: [],
            facets: ["brand"],
            currentPage:1,
            price_min: 0,
            price_max:1000,
            sort : [],
            filteredbrands: [],           // List of filtered brands
            visibleBrands: 5,             // Number of brands to show at a time
            searchKeyword: '',            // Search keyword entered by the user
        }
    },
    props: {
        search: {
            type: String,
            required: true
        },
        selectedBrands: {
            type: Array,
            default: () => []
          },
        comingFromBrands:{
            type: Boolean,
            default:false
        },
        
    },
    emits:['selected-brands-updated', 'price-filter-change', 'sort-change'],
    mounted() {
        rangeSlider(this.price_min, this.price_max);
        document.addEventListener('slider-value-changed', this.handleSliderValueChanged);
    }
}
</script>

<template>
<span class="mb-4 breadcrumb"><strong>Home</strong> > Search > {{ search }}</span>
<div class="row">
  <a @click.prevent="showFilters();" href="#">
    <div class="filter-title mb-4 ps-1 d-lg-none">
      <img :src="require('@/assets/images/filters.svg')" width="15" class="me-2" /> Filters
    </div>
  </a>
  <div class="d-none d-lg-block col-lg-3 pb-4 pt-2 filter-title">
    <h2>Refine</h2>
  </div>
  <div class="mud-d-none ps-lg-4 d-lg-block col-lg-9 pb-4 pt-2 filter-sort filter">
    <strong class="pe-5 sort-by">Sort by</strong>
    <label class="me-4 filter-radio-label">
      <input
        class="form-check-input form-check-input-radio me-2"
        name="radioSort"
        checked="checked"
        type="radio"
        value=""
        id="flexCheckDefault"
        @change="radioSortChange('')"
      />
    Relevance</label>
    <label class="me-4 filter-radio-label">
      <input
        class="form-check-input form-check-input-radio me-2"
        name="radioSort"
        type="radio"
        value=""
        id="flexCheckDefault"
        @change="radioSortChange('asc')"
      />
    Lower price</label>
    <label class="filter-radio-label">
      <input
        class="form-check-input form-check-input-radio me-2"
        name="radioSort"
        type="radio"
        value=""
        id="flexCheckDefault"
        @change="radioSortChange('desc')"
      />
    Higher price</label>
  </div>
</div>
<div class="mud-d-none d-lg-block col-lg-3 filter">
  <div class="search-filter p-3 mb-5">
    <h3 class="fs-6 filter-title mb-0">
      Price
    </h3>
    <div id="range" ref="priceRangeSlider"></div>
    <div class="row ps-1 pe-1">
      <div class="col-5 mt-3">
        <input type="number" id="from" class="range-price-input" v-model="price_min" />
      </div>
      <div class="col-2 mt-3 text-center filter-to">
        to
      </div>
      <div class="col-5 mt-3">
        <input type="number" id="to" class="range-price-input" v-model="price_max" />
      </div>
    </div>
    <div class="row pt-3 ps-0 mb-4">
      <a
        href="#"
        class="btn-appliquer"
        @click="ApplyPriceFilter"
        >Apply</a
      >
    </div>
    <h3 class="fs-6 mt-4 filter-title">Range prices</h3>
    <div class="form-check mt-2">
      <input
        class="form-check-input"
        name="radioPrices"
        type="radio"
        value=""
        id="flexCheckDefault"
        @change="radioChange(0,25)"
      />
      <label class="form-check-label" for="flexCheckDefault">
        Less than 25 $
      </label>
    </div>
    <div class="form-check mt-1">
      <input
        class="form-check-input"
        name="radioPrices"
        type="radio"
        value=""
        id="flexCheckDefault"
        @change="radioChange(25,50)"
      />
      <label class="form-check-label" for="flexCheckDefault"> 25 to 50 $ </label>
    </div>
    <div class="form-check mt-1">
      <input
        class="form-check-input"
        name="radioPrices"
        type="radio"
        value=""
        id="flexCheckDefault"
        @change="radioChange(50,80)"
      />
      <label class="form-check-label" for="flexCheckDefault"> 50 to 80 $ </label>
    </div>
    <div class="form-check mt-1">
      <input
        class="form-check-input"
        name="radioPrices"
        type="radio"
        value=""
        id="flexCheckDefault"
        @change="radioChange(80,160)"
      />
      <label class="form-check-label" for="flexCheckDefault">
        80 to 160 $
      </label>
    </div>
    <div>
      <h3 class="fs-6 mt-4 filter-title mb-2">Brands</h3>

      <div style="margin-bottom: 10px">
        <input
          id="autocomplete"
          @input="filter_brands"
          v-model="searchKeyword"
          type="search"
          name="search"
          class="form-control form-control-dark text-bg-white brand-finder"
          placeholder="Find brands..."
          aria-label="Search"
        />
      </div>

      <div class="brands-container overflow-auto" style="max-height: 150px">
        <div
          v-for="(total, brand) in filteredbrands"
          :key="brand"
          class="form-check mt-1"
        >
          <input
            class="form-check-input"
            type="checkbox"
            :value="brand"
            :id="'checkbox-' + brand"
            @click="updateSelectedBrands"
          />
          <label class="form-check-label" :for="'checkbox-' + brand">
            {{ brand }} ({{ total }})
          </label>
        </div>
      </div>
    </div>
  </div>
</div>
</template>