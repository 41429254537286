export const graphQLApiUrl = process.env.VUE_APP_URL_GRAPHQL_SERVER;
export const searchEngine = process.env.VUE_APP_URL_MEILISEARCH_SERVER;
export const baseServer = process.env.VUE_APP_URL_ADMIN_SERVER;

export function truncateText(text, length = 60, clamp = '...') {
    if (text.length <= length) return text;
    let truncatedText = text.slice(0, length - clamp.length);
    truncatedText = truncatedText.slice(0, Math.max(truncatedText.lastIndexOf(' '), truncatedText.lastIndexOf('\n')));
    let out = truncatedText + clamp;

    const textarea = document.createElement("textarea");
    textarea.innerHTML = out;
    return textarea.value;
}

export function scrolltop()
{
    window.scrollTo(0, 0);
}

export function merchantImage(name)
{
    return baseServer + '/assets/media/merchants/' + name.toLowerCase().replace(/ /g, '_') + '.png';
}

export function HTMLparse(text) {
    return text.replace(/<\/?[^>]+(>|$)/g, "");
}

export function rangeSlider(priceMin, priceMax) {
    /*$("#range").ionRangeSlider({
        type: "double",
        skin: "round",
        grid: true,
        min: 0,
        max: 1000,
        from: priceMin,
        to: priceMax,
        prefix: "$",
        onChange: function (data) {
            document.getElementById('from').value = data.from
            document.getElementById('to').value = data.to
            const event = new CustomEvent('slider-value-changed', {
                detail: { from: data.from, to: data.to },
              });
            document.dispatchEvent(event);
        },
        onStart: function (data) {
            document.getElementById('from').value = data.from
            document.getElementById('to').value = data.to
        }
    });*/

    return [priceMin, priceMax];
}

export function urlfriendly(value) {
    if (!value) return ''
    value = value.toLowerCase()
    value = value.replace(/ /g, '-')
    return value
}

function autocompleteResizeWindows()
{
    var element = document.getElementById("autocomplete");

    if(element)
    {
        const rect = element.getBoundingClientRect();
        var y = rect.y + element.offsetTop + document.scrollingElement.scrollTop;
        var height = element.offsetHeight;
        var width = element.offsetWidth;

        const result = document.getElementById('autocomplete-results');
        result.style.left = rect.x + 'px';
        result.style.top = (y + height) + 'px';
        result.style.width = width + 'px';
    }
}

function autocompleteResizeMenu()
{
    var element = document.getElementById("autocomplete");

    if(element)
    {
        const rect = element.getBoundingClientRect();
        var y = rect.y + element.offsetTop + document.scrollingElement.scrollTop;
        var height = element.offsetHeight;
        var width = element.offsetWidth;

        const result = document.getElementById('autocomplete-results');
        result.style.left = rect.x + 'px';
        result.style.top = (y + height) + 'px';
        result.style.width = width + 'px';
        result.style.display = "block";
    }
}

export function autocompleteInit()
{
    window.addEventListener("resize", function() {
        autocompleteResizeWindows();
    });

    document.addEventListener("click", function(event) {
        var autocomplete = document.getElementById("autocomplete-results");
        var field = document.getElementById("autocomplete");
        if (event.target != field && event.target != autocomplete && !autocomplete.contains(event.target)) {
            const result = document.getElementById('autocomplete-results');
            result.style.display = "none";
        }
    });
}

export function preSearch(q)
{
    autocompleteResizeMenu();

    const xhr = new XMLHttpRequest()
    const url = searchEngine + '/indexes/products/search'
    const query = q
    //const filters = 'price > 1 AND price < 10'
    const limit = 7
    const offset = 0
    //const attributesToRetrieve = ['brand']

    xhr.open('POST', url)
    xhr.setRequestHeader('Content-Type', 'application/json')
    xhr.onload = function () {
        if (xhr.status === 200) {
            var list = JSON.parse(xhr.response).hits;
            const results = document.getElementById('autocomplete-results');
            results.innerHTML = '<span class="search-autocomplete-title">MATCHED PRODUCTS</span>';
            list.forEach(function(e){
                results.insertAdjacentHTML('beforeend', '<a href="/product/test/'+e._id+'" class="result-item"><img width="50" src="'+e.image[0]+'"><p>'+e.name+'<br><i style="color:#999">'+e.brand+'</i></p></a>');
            });
        }
    }
    xhr.send(
        JSON.stringify({
            q: query,
            limit,
            offset
        })
    )
}

export function meiliSearch(params)
{
    const xhr = new XMLHttpRequest()
    const url = searchEngine + '/indexes/products/search'

    if (typeof params.q === 'undefined' || params.q === null) {
        return false;
    }

    if (typeof params.hitsPerPage === 'undefined' || params.hitsPerPage === null) {
        return false;
    }

    if (typeof params.page === 'undefined' || params.page === null) {
        return false;
    }

    if (typeof params.filter === 'undefined' || params.filter === null) {
        return false;
    }

    const query = params.q
    const hitsPerPage = params.hitsPerPage
    const page = params.page
    const filter = params.filter
    const sort = params.sort
    xhr.open('POST', url)
    xhr.setRequestHeader('Content-Type', 'application/json')

    function getData() {
        return new Promise(function(resolve, reject) {
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var list = JSON.parse(xhr.response).hits;
                    resolve(list);
                } else {
                    reject(Error(xhr.statusText));
                }
            };
            xhr.onerror = function () {
                reject(Error("Network Error"));
            };
            xhr.send(
                JSON.stringify({
                    q: query,
                    hitsPerPage,
                    page,
                    filter,
                    sort
                })
            )
        });
    }
    
    return getData();
}

export function meiliSearchTotalCount(params) {
    const xhr = new XMLHttpRequest()
    const url = searchEngine + '/indexes/products/search'

    if (typeof params.q === 'undefined' || params.q === null) {
        return false;
    }

    if (typeof params.hitsPerPage === 'undefined' || params.hitsPerPage === null) {
        return false;
    }

    if (typeof params.page === 'undefined' || params.page === null) {
        return false;
    }

    if (typeof params.filter === 'undefined' || params.filter === null) {
        return false;
    }

    const query = params.q
    const hitsPerPage = params.hitsPerPage
    const page = params.page
    const filter = params.filter
    const sort = params.sort
    console.log("whatsort",sort)
    xhr.open('POST', url)
    xhr.setRequestHeader('Content-Type', 'application/json')
    
    function getData() {
        return new Promise(function(resolve, reject) {
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var list = JSON.parse(xhr.response).hits;
                    var totalPages = JSON.parse(xhr.response).totalPages;
                    
                    resolve({list, totalPages});
                } else {
                    reject(Error(xhr.statusText));
                }
            };
            xhr.onerror = function () {
                reject(Error("Network Error"));
            };
            xhr.send(
                JSON.stringify({
                    q: query,
                    hitsPerPage,
                    page,
                    filter,
                    sort
                    
                })
            )
        });
    }
    
    return getData();
}


export function meiliSearchBrands(params) {
    const xhr = new XMLHttpRequest()
    const url = searchEngine + '/indexes/products/search'

    if (typeof params.q === 'undefined' || params.q === null) {
        return false;
    }

    if (typeof params.facets === 'undefined' || params.facets === null) {
        return false;
    }

    if (typeof params.hitsPerPage === 'undefined' || params.hitsPerPage === null) {
        return false;
    }

    if (typeof params.page === 'undefined' || params.page === null) {
        return false;
    }
    if (typeof params.filter === 'undefined' || params.filter === null) {
        return false;
    }
    const query = params.q
    const hitsPerPage = params.hitsPerPage
    const page = params.page
    const facets = params.facets
    const filter = params.filter

    xhr.open('POST', url)
    xhr.setRequestHeader('Content-Type', 'application/json')
    
    function getData() {
        return new Promise(function(resolve, reject) {
            xhr.onload = function () {
                if (xhr.status === 200) {
                    var list = JSON.parse(xhr.response).facetDistribution.brand;

                    
                    resolve(list);
                } else {
                    reject(Error(xhr.statusText));
                }
            };
            xhr.onerror = function () {
                reject(Error("Network Error"));
            };
            xhr.send(
                JSON.stringify({
                    q: query,
                    facets,
                    hitsPerPage,
                    page,
                    filter
         
                })
            )
        });
    }
    
    return getData();
}

export function updatewishlist(element){
    this.$nextTick(function() {
        this.$forceUpdate();
    });
    
    this.addToWishlist(element);    
    const event = new CustomEvent('update-header', {});
    
    document.dispatchEvent(event);
}

export function addToWishlist(element) {
    // Retrieve the existing wishlist or create a new empty array
    const wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
  
    const existingElement = wishlist.find(item => item._id === element._id);
  
    if (existingElement) {
      // Product already exists in the wishlist, remove it
      const index = wishlist.indexOf(existingElement);
      wishlist.splice(index, 1);
    } else {
      // Product doesn't exist in the wishlist, add it
      element.urlfriendly = 'makeup-deal.com/product/'+urlfriendly(element.name)+'/'+element._id
      wishlist.push(element);
    }
  
    // Save the updated wishlist back to local storage
    localStorage.setItem('wishlist', JSON.stringify(wishlist));
  }

export function inWishlist(element){
    // Retrieve the existing wishlist or create a new empty array
    const wishlist = JSON.parse(localStorage.getItem('wishlist')) || [];
    const existingElement = wishlist.find(item => item._id === element._id);
    return existingElement                      
}

export function loading(){
    const event = new CustomEvent('is-loading', {
        detail: false,
        });
      document.dispatchEvent(event); 
}

export function priceFormat(price) {
    let num = price.toFixed(2);
    let decimales = num.toString().split('.')[1];
    if (decimales) {
        decimales = '.' + decimales;
    }
    return num.toString().replace(decimales, ',' + num.toString().split('.')[1]) + ' €';
}