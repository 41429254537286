<script>
import { graphQLApiUrl, priceFormat,loading, scrolltop,updatewishlist,addToWishlist,inWishlist } from "../helpers.js"
import MainHeader from './elements/MainHeader.vue'
import FooterSection from './elements/FooterSection.vue'
import RelatedProducts from './elements/product/RelatedProducts.vue'
import PricesList from './elements/product/PricesList.vue'
import DescriptionSection from './elements/product/DescriptionSection.vue'
import ImageMud from './elements/product/ImageMud.vue'

export default {
    components: {
        MainHeader,
        FooterSection,
        RelatedProducts,
        PricesList,
        DescriptionSection,
        ImageMud
    },
    methods: {
        scrolltop,
        priceFormat,
        getProduct() {
            const url = graphQLApiUrl

            const query = `
            query {
                product(_id: "`+ this.id +`") {
                    _id
                    name
                    price
                    category
                    currency
                    last_updated
                    url
                    _brand {
                        name
                    }
                    description
                    ean
                    isbn
                    sku
                    upc
                    thumbnail
                    images
                    reviews
                    rating
                    color
                    gender
                    breadcrum {
                        id
                        name
                    }
                    productsDataFeedr {
                        name
                        currency
                        finalprice
                        merchant
                        url
                        brand
                        Deliverytime
                        baseprice
                        instock
                        lastupdated
                    }
                }
            }`;

            const featured_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
            },
                body: JSON.stringify({ query: query })
            };

            fetch(url, featured_options).then(response => response.json()).then(data => {
                this.product = data.data.product;
                this.brand = data.data.product._brand.name;

                document.title = 'MakeupDeal * - ' + this.product.name;
            })
            .catch(error => {
                console.error(error);
            });
        },
        inWishlist,
        updatewishlist,
        addToWishlist,
    },
    data() {
        return {
            name: '',
            id: '',
            product: '',
            brand: '',
            ia_table: null
        }
    },
    mounted() {
        scrolltop();
        document.title = 'MakeupDeal * - Product';
        this.name = this.$route.params.name;
        this.id = this.$route.params.id;
        this.getProduct();
        
        
    },
    updated(){
      this.$nextTick(()=> {
        const myElement = this.$refs.image;
        if (myElement) {
        myElement.onload = loading();}
      }

      )
      
    }
   
  
}
</script>

<template>
  <main-header />
  <div class="container mb-5">
    <div class="row">
      <div class="col-lg-12 pt-3 pb-5 fs-6" >
        <strong>Home</strong> > Search > Product
      </div>
    </div>
    <div class="row mb-4" v-if="product" >
      <aos-vue  class="col-lg-3 text-center" animation="fade-right">
        <image-mud ref="image" :class="'product-img pb-3'" :list="product.images" />
      </aos-vue>
      <div  class="col-lg-9">
        <p class="mb-0 brand-text">{{product._brand.name}}</p>
        <strong class="title-text">{{ product.name }}</strong>
        <div class="mb-3 mt-4">
          <span class="price-featured mud-h">From<br><p class="fs-4 price fw-600" v-html="priceFormat(product.price)"></p></span>
        </div>
        <div class="mt-5">
        <a
          href="#"
          class="mud-button-four me-2 mt-3"
          @click.prevent="updatewishlist(product);"
        >
          <svg
            class="hearth"
            xmlns="http://www.w3.org/2000/svg"
            width="18"
            height="18"
            viewBox="0 0 24 24"
            style="vertical-align: text-bottom"
          >
            <path
              :class="{ 'filled-heart': inWishlist(product) }"
              :fill="inWishlist(product) ? '#948E8E' : 'none'"
              d="M21.3 3.8a5.6 5.6 0 00-8.1 0L12 5l-1.2-1.2a5.6 5.6 0 00-8.1 0 6.2 6.2 0 000 8.5l1.2 1.2L12 22l8.1-8.5 1.2-1.2a6.2 6.2 0 000-8.5z"
            ></path>
          </svg>
          Add to Wishlist
        </a>
        </div>
      </div>
    </div>
    <div class="row pt-5">
      <ul class="nav nav-tabs" id="myTab" role="tablist">
        <li class="nav-item" role="presentation">
          <button class="nav-link mud-tab active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Compare prices</button>
        </li>
        <li class="nav-item" role="presentation">
          <button class="nav-link mud-tab" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Product description</button>
        </li>
      </ul>
      <div class="tab-content" id="myTabContent">
        <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
          <prices-list :product="product" />
        </div>
        <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
          <description-section :id="id" />
        </div>
      </div>
      <related-products :brand="brand" />
    </div>
  </div>

  <footer-section />
</template>