<script>
import { graphQLApiUrl, truncateText, inWishlist, urlfriendly, priceFormat } from "../../../helpers.js"
import ImageMud from './ImageMud.vue'

export default {
    components: {
        ImageMud
    },
    methods: {
        truncateText,
        urlfriendly,
        priceFormat,
        inWishlist,
        getFeatureProducts(search) {
            const url = graphQLApiUrl

            const query = `
            query {
                products(filter: {
                    name: "`+search+`",
                    pagination: {
                        page: 1,
                        itemsPerPage: 5
                    }
                })
                {
                    data {
                        _id
                        name
                        price
                        images
                        _brand {
                          name
                        }
                    }
                }
            }`;

            const featured_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
            },
                body: JSON.stringify({ query: query })
            };

            fetch(url, featured_options).then(response => response.json()).then(data => {
                this.products = data.data.products.data;
            })
            .catch(error => {
                console.error(error);
            });
        }
    },
    mounted() { },
    updated() { },
    data() {
        return {
            products: '',
        }
    },
    props: {
        brand: {
            type: String,
            required: true
        }
    },
    watch: {
        brand(e) {
            this.getFeatureProducts(e);
        }
    }
}
</script>

<template>
  <div class="container">
  <h1 class="text-center pt-5 mt-5 mud-h">Related products</h1>
    <div class="product-grid row py-5 row-cols-xs-2 row-cols-lg-5">
        <div animation="zoom-in" :once="true" :duration="300" :delay="100 * index" class="position-relative feature col product-div" v-for="(element, index) in products" v-bind:key="index">
            <a href="#" @click.prevent="updatewishlist(element);">
                <svg class="hearth" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                    <path :class="{ 'filled-heart': inWishlist(element) }" :fill="inWishlist(element) ? '#948E8E' : 'none'" d="M21.3 3.8a5.6 5.6 0 00-8.1 0L12 5l-1.2-1.2a5.6 5.6 0 00-8.1 0 6.2 6.2 0 000 8.5l1.2 1.2L12 22l8.1-8.5 1.2-1.2a6.2 6.2 0 000-8.5z"></path>
                </svg>
            </a>
            <div class="text-center mb-3">
            <router-link :to="{ name: 'product', params: { name: this.urlfriendly(element.name), id: element._id } }">
                <image-mud :class="'image-grid-home'" :list="element.images" />
            </router-link>
            </div>
            <h3 class="fs-6 fw-bold product-title mud-h">{{ element._brand.name }}</h3>
            <span class="brand mud-h">{{ truncateText(element.name,45) }}</span>
            <div class="text-start pt-3 pb-3">
                <span class="price-featured mud-h">From<br><p class="fs-4 price fw-600" v-html="priceFormat(element.price)"></p></span>
            </div>
          </div>
    </div>
  </div>
</template>