<script>
import { graphQLApiUrl, loading } from "../helpers.js"
import MainHeader from './elements/MainHeader.vue'
import FooterSection from './elements/FooterSection.vue'

export default {
    components: {
        MainHeader,
        FooterSection,
    },

    methods: {
        createSearchUrl(brand) {
          const params = new URLSearchParams();
          params.append('selectedBrands', JSON.stringify([brand]));
          return '/search/?' + params.toString();
        }
      },
    data() {
        // Definimos la URL del API GraphQL
        const url = graphQLApiUrl;

        // Definimos la consulta GraphQL que queremos hacer
        const query = `
        query {
            brands{
               name
            }
        }`;

        // Definimos las opciones para la llamada fetch
        const options = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ query })
        };

        // Hacemos la llamada fetch a la URL del API GraphQL con las opciones definidas
        fetch(url, options)
        .then(response => response.json())
        .then(data => {
            data.data.brands.forEach((brand) => {
                let firstLetter = brand.name.charAt(0).toUpperCase();

                // Check if the first letter is a number or special character
                if (!firstLetter.match(/[A-Z]/)) {
                  firstLetter = '0-9'; // Group them under '0-9' category
                }

                if (!this.brandNamesByLetter[firstLetter]) {
                  this.brandNamesByLetter[firstLetter] = [];
                }

                this.brandNamesByLetter[firstLetter].push(brand.name);
              });
            console.log(this.brandNamesByLetter);
        })
        .catch(error => {
            // Aquí puedes manejar el error si la llamada falla
            console.error(error);
        });

        return {
            brandNamesByLetter: {},
        }
    },
    computed: {
        sortedLetters() {
          return Object.keys(this.brandNamesByLetter).sort();
        }
      },
    updated(){
      loading();
    },
    
}
</script>

<template>
  <main-header />
  <div class="container mt-5 pt-5">
    <h1 class="mb-5 mud-h text-center">Explore our brands</h1>
    <div
      v-for="letter in sortedLetters"
      :key="letter"
      class="row mb-4 pb-4 brand-block"
    >
      <div class="col-lg-2">
        <strong>{{ letter }}</strong>
      </div>
      <div class="col-lg-10">
        <div class="row">
          <div
            v-for="brand in brandNamesByLetter[letter]"
            :key="brand"
            class="col-lg-3"
          >
            <a
              class="text-black text-decoration-none"
              :href="createSearchUrl(brand)"
              >{{ brand }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer-section />
</template>