<script>
import MainHeader from './elements/MainHeader.vue'
import SliderSection from './elements/SliderSection.vue'
import FeaturedCategories from './elements/FeaturedCategories.vue'
import FeaturedProducts from './elements/FeaturedProducts.vue'
import FooterSection from './elements/FooterSection.vue'
import AdBanner from './elements/banners/AdBanner.vue'

export default {
    name: 'HomePage',
    components: {
        MainHeader,
        SliderSection,
        FeaturedCategories,
        FeaturedProducts,
        FooterSection,
        AdBanner
    },
    mounted() {
        document.title = 'MakeupDeal * | Home';
        
        
    },
    
}
</script>

<template>
    <main-header />
    <slider-section />
    <featured-categories />
    <ad-banner />
    <featured-products />
    <footer-section />
</template>