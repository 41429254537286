<script>
import {  loading} from "../helpers.js"

import MainHeader from './elements/MainHeader.vue'
import SearchFilter from './elements/SearchFilter.vue'
import ProductGrid from './elements/ProductGrid.vue'
import FooterSection from './elements/FooterSection.vue'

export default {
    components: {
        MainHeader,
        SearchFilter,
        ProductGrid,
        FooterSection,
    },
    methods: {
        handleSortChange(sort){
            this.sort = sort
        },
        handleSelectedBrandsUpdated(selectedBrands) {
            this.selectedBrands = selectedBrands;
        },
        handlePriceFilterChange(prices){
            const {price_min,price_max} = prices;
            this.price_min=price_min
            this.price_max=price_max
        },
        // Add a product ID to the wishlist
        
    },
    data() {
        return {
            search: '',
            selectedBrands:[],
            comingFromBrands:false,
            price_min:0,
            price_max:1000,
            sort : []
        }
    },
    mounted() {
        document.title = 'MakeupDeal * - Search';
        this.search = this.$route.params.id ;

    },
    updated(){
      loading();
    },
    created() {
        const urlParams = new URLSearchParams(window.location.search);
        if (urlParams.has('selectedBrands')) {
        const selectedBrands = JSON.parse(decodeURIComponent(urlParams.get('selectedBrands')));
        this.selectedBrands = selectedBrands;
        this.comingFromBrands=true;

      }
    }
}
</script>

<template>
  <main-header />
  <div class="container mb-5">
    <div class="row mt-3">
        <search-filter
          :search="search"
          :selected-brands="selectedBrands"
          :comingFromBrands="comingFromBrands"
          @selected-brands-updated="handleSelectedBrandsUpdated"
          @Price-Filter-Change="handlePriceFilterChange"
          @Sort-Change="handleSortChange"
        />
      <div class="product-grid col-lg-9 row-cols-2 row-cols-lg-4 ps-3 pe-3">
        <product-grid
          :search="search"
          :selected-brands="selectedBrands"
          :price_min="price_min"
          :price_max="price_max"
          :sort="sort"
        />
      </div>
    </div>
  </div>
  <footer-section />
</template>
