<script>
import { graphQLApiUrl, priceFormat, scrolltop, loading } from "../helpers.js"
import MainHeader from './elements/MainHeader.vue'
import FooterSection from './elements/FooterSection.vue'

export default {
    components: {
        MainHeader,
        FooterSection
},
    methods: {
        scrolltop,
        priceFormat,
        getPage() {
            const url = graphQLApiUrl

            const query = `
            query {
                blogposts(
                    where: {
                        column: SLUG,
                        value: "`+ this.slug +`"
                    }) {
                    data {
                        title
                        slug
                        description
                        published
                        article
                        category
                        author
                        thumbnail
                        status
                    }
                    paginatorInfo {
                        currentPage
                        lastPage
                    }
                }
            }`;

            const featured_options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
            },
                body: JSON.stringify({ query: query })
            };

            fetch(url, featured_options).then(response => response.json()).then(data => {
                if (data.data.blogposts.data[0])
                {
                    let page = data.data.blogposts.data[0];
                    this.content = page.article;
                    document.title = 'MakeupDeal * - ' + page.title;
                }
                else
                {
                    document.location = '/404';
                }
            })
            .catch(error => {
                console.error(error);
            });
        },
        executeScriptTags() {
            const scriptTags = document.querySelectorAll(`script`);
            scriptTags.forEach(script => {
                (function(scope) {
                eval.call(scope, script.innerText);
                })(window);
            });
        }
    },
    data() {
        return {
            slug: '',
            content: ''
        }
    },
    mounted() {
        scrolltop();
        document.title = 'MakeupDeal * - ';
        this.slug = this.$route.params.slug;
        this.getPage();
        
    },
    updated(){
      loading();
      this.executeScriptTags();
    },
}
</script>

<template>
  <main-header />
  <div id="pingo" v-html="content"></div>
  <footer-section />
</template>