<script>
import { graphQLApiUrl, loading } from "../helpers.js"
import MainHeader from './elements/MainHeader.vue'
import FooterSection from './elements/FooterSection.vue'

export default {    
    components: {
        MainHeader,
        FooterSection,
    },
    methods: {
        getPost() {
            const url = graphQLApiUrl;

            const query = `
                query {
                    blogposts(where: {
                        column: SLUG
                        value: "`+this.slug+`"
                    }){
                        data {
                            title
                            slug
                            description
                            published
                            article
                            category
                            author
                            thumbnail
                            status
                            _category {
                                name
                                slug
                                description
                            }
                        }
                    }
                }`;

            const options = {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ query })
            };

            fetch(url, options)
            .then(response => response.json())
            .then(data => {
                data.data.blogposts.data.forEach((data) => {
                    this.post = data
                });
            })
            .catch(error => {
                console.error(error);
            });
        },
        executeScriptTags() {
            const scriptTags = document.querySelectorAll(`script`);
            scriptTags.forEach(script => {
                (function(scope) {
                eval.call(scope, script.innerText);
                })(window);
            });
        }
    },
    data() {
        return {
            slug: null,
            post: []
        }
    },
    mounted() {
        this.slug = this.$route.params.slug;
        this.getPost();
    },
    updated(){
      loading();
      this.executeScriptTags();
    }
}
</script>

<template>
    <main-header />
    <div>
        <h1 class="mb-5 mud-h text-center mt-5 pt-5">{{ post.title }}</h1>
        <div class="container" v-html="post.article"></div>
    </div>
    <footer-section />
</template>