<script>
import { truncateText, HTMLparse, urlfriendly, meiliSearch, meiliSearchTotalCount, updatewishlist, inWishlist, addToWishlist, priceFormat} from "../../helpers.js"
import ImageMud from './product/ImageMud.vue'

export default {
    components: {
      ImageMud
    },
    methods: {
        truncateText,
        HTMLparse,
        urlfriendly,
        meiliSearch,
        updatewishlist,
        addToWishlist,
        inWishlist,
        priceFormat,
        paginar() {
            var self = this;
            self.products = []
            let filter = '';

            if (this.selectedBrands.length === 0) {
                filter = 'price > ' + this.price_min + ' AND price < ' + this.price_max;
            } else {
                const brandFilter = this.selectedBrands.map(brand => 'brand = "' + brand+'"').join(' OR ');
                filter = 'price > ' + this.price_min + ' AND price < ' + this.price_max + ' AND (' + brandFilter + ')';
            }
            meiliSearch({
                q: this.search,
                hitsPerPage: 24,
                page: this.currentPage,
                filter: filter,
                sort:this.sort
            }).then(function(list) {

                self.products = list;
            }).catch(function(error) {
                console.log(error);
            });
        },
        getCount() {
            const self = this;
            let filter = '';

            if (this.selectedBrands.length === 0) {
                filter = 'price > ' + this.price_min + ' AND price < ' + this.price_max;
            } else {
                const brandFilter = this.selectedBrands.map(brand => 'brand = "' + brand+'"').join(' OR ');
                filter = 'price > ' + this.price_min + ' AND price < ' + this.price_max + ' AND (' + brandFilter + ')';
            }
            meiliSearchTotalCount({
                q: this.search,
                hitsPerPage: 24,
                page: this.currentPage,
                filter: filter,
                sort:this.sort
            }).then(function(response) {
                self.totalPages = response.totalPages // Set the totalCount data property

            }).catch(function(error) {
                console.log(error);
            });
        },
    },
    watch: {
        search() {
            this.getCount()
            this.paginar()
        },
        selectedBrands() {
            this.currentPage=1;
            this.getCount();
            this.paginar();
          },
        price_min(){
            this.getCount()
            this.paginar()
        },
        price_max(){
            this.getCount()
            this.paginar()
        },
        sort(){
            this.getCount()
            this.paginar()
        },
    },
    data() {
        return {
            products: [],
            limit: 12,
            offset: 0,
            currentPage: 1,
            totalPages: 0,
            filter: '',
            whislistProducts:[]
        }
    },
    props: {
        search: {
            type: String,
            required: true
        },
        selectedBrands: {
            type: Array,
            default: () => []
        },
        price_min:{
            type: Number,
            required: true
        },
        price_max:{
            type: Number,
            required: true
        },
        sort:{
            type: Array,
            required: true
        }
    },
    created(){
        this.currentPage=1
    }
}
</script>

<template>
  <div class="position-relative feature product-div" v-for="(element, index) in products" :key="index">
    <a @click.prevent="updatewishlist(element);" href="#" class="position-absolute top-10 right-10">
      <svg
        class="hearth"
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
      >
        <path
          :class="{ 'filled-heart': inWishlist(element) }"
          :fill="inWishlist(element) ? '#948E8E' : 'none'"
          d="M21.3 3.8a5.6 5.6 0 00-8.1 0L12 5l-1.2-1.2a5.6 5.6 0 00-8.1 0 6.2 6.2 0 000 8.5l1.2 1.2L12 22l8.1-8.5 1.2-1.2a6.2 6.2 0 000-8.5z"
        ></path>
      </svg>
    </a>
    <router-link
      :to="{ name: 'product', params: { name: urlfriendly(element.name), id: element._id } }"
    >
      <div class="text-center mb-3">
        <image-mud :class="'mt-5 product-grid-image mb-4'" :list="element.image" />
      </div>
    </router-link>
    <h3 class="fs-6 fw-bold product-title mud-h">{{ element.brand }}</h3>
    <span class="brand mud-h">{{ truncateText(element.name,55) }}</span>
    <div class="text-start pt-3 pb-3">
        <span class="price-featured mud-h">From<br><p class="fs-4 price fw-600" v-html="priceFormat(element.price)"></p></span>
    </div>
  </div>

  <div class="pagination-mud">
    <nav aria-label="Page navigation example">
      <ul class="pagination justify-content-center">
        <li
          class="page-item"
          v-if="currentPage > 1"
          @click="currentPage--,paginar()"
        >
          <a class="page-link" href="#">Previous</a>
        </li>
        <li class="page-item" v-for="pageNumber in totalPages" v-bind:key="pageNumber">
          <a
            v-if="Math.abs(currentPage - pageNumber) < 3 || pageNumber == 1 || pageNumber == totalPages"
            :class="{active: pageNumber === currentPage}"
            @click="() => {currentPage = pageNumber; paginar();}"
            class="page-link"
            href="#"
            >{{pageNumber}}</a
          >
        </li>
        <li class="page-item" @click="currentPage++,paginar()">
          <a class="page-link" href="#">Next</a>
        </li>
      </ul>
    </nav>
  </div>
</template>