<script>
import {baseServer, truncateText, HTMLparse, urlfriendly, meiliSearch, loading, updatewishlist, addToWishlist, inWishlist, priceFormat} from "../helpers.js"

import MainHeader from './elements/MainHeader.vue'
import FooterSection from './elements/FooterSection.vue'

export default {
  components: {
      MainHeader,
      FooterSection,
  },
  methods: {
      truncateText,
      priceFormat,
      HTMLparse,
      urlfriendly,
      meiliSearch,
      updatewishlist,
      addToWishlist,
      inWishlist,
      getImageUrl(element) {
          if (element.image) {
              // If 'image' property exists, use it
              return element.image[0];
          } else if (element.images) {
              // If 'images' property exists, use it
              return element.images[0];
          } else {
              // Handle the case where neither 'image' nor 'images' property exists
              return ''; // Provide a default or fallback image URL
          }
      },
      totalAmount() {
        let total = 0;

        this.products.forEach(function(e){
          total = total + e.price;
        });

        return total;
      },
      async sendEmail(recipient) {
        const url = baseServer+'/api/wishlist/email';
        const data = { wishlist: this.products, recipient:recipient };

        try {
          const response = await fetch(url, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
          });

          if (response.ok) {
            const result = await response.json();
            this.showSuccessNotification(result.message);
            console.log(result.message);
          } else {
            console.error('Failed to send email.');
          }
        } catch (error) {
          this.showSuccessNotification(error);          
          console.error(error);
        }
      },
      showSuccessNotification(message) {
      // Display success notification using browser's built-in notification or an alert box
      if (window.Notification && Notification.permission === 'granted') {
        new Notification('Success', { body: message });
      } else {
        alert('Success: ' + message);
      }
    },
    showErrorNotification(message) {
      // Display error notification using browser's built-in notification or an alert box
      if (window.Notification && Notification.permission === 'granted') {
        new Notification('Error', { body: message });
      } else {
        alert('Error: ' + message);
      }
    },
    handleWishlist(){
      this.products = JSON.parse(localStorage.getItem('wishlist')) || [];
    }
      





  },
  data() {
      return {
          email:"",
          products: [],

      }
  },
  mounted() {
      this.products = JSON.parse(localStorage.getItem('wishlist')) || [];
      document.addEventListener('update-header', this.handleWishlist);
  },
  updated(){
    this.$nextTick(()=> {
        const myElement = this.$refs.load;
        if (myElement) {
        myElement.onload = loading();}
        else if (this.products.length === 0) {

          loading();
        }
      })
      
    },
}
</script>

<template>
  <main-header />
  <div class="container mt-5 pt-5">
    <h1 class="mb-5 mud-h text-center">My wishlist</h1>
    <div v-if="products.length==0" class="mud-h">You have no products in your wishlist</div>
    <div v-if="products.length >0" class="row">
      <div class="col-lg-9 mb-5 row-cols-lg-1">
        <div 
          class="pt-3 position-relative feature col product-div row pb-3"
          v-for="(element, index) in products" :key="index"
          animation="zoom-in"
        >
          <div class="col-3 col-lg-2 mb-3">
            <router-link
              :to="{ name: 'product', params: { name: urlfriendly(element.name), id: element._id } }"
            >
            <img ref="load" :src="getImageUrl(element)" width="100" />
            </router-link>
          </div>
          <div class="col-9 col-lg-5">
            <span class="brand mud-h">{{ truncateText(element.name,45) }}</span>
            <p class="product-wishlist fw-100">Brand: <span class="fs-6 fw-bold product-title mud-h">{{ element.brand }}</span></p>
            <p class="product-wishlist fw-100"><router-link class="mud-logo-subtext" :to="{ name: 'product', params: { name: urlfriendly(element.name), id: element._id } }">View offers</router-link></p>
          </div>
          <div class="col-lg-4 d-flex justify-content-center align-items-center">
            <span class="price-featured mud-h">From<br><p class="fs-4 price fw-600" v-html="priceFormat(element.price)"></p></span>
          </div>
          <div class="col-lg-1 d-flex justify-content-center align-items-center">
            <a href="#" @click.prevent="updatewishlist(element);">
              <img :src="require('@/assets/images/close.svg')" width="30" />
            </a>
          </div>
        </div>
      </div>
      <div class="col-lg-3">
        <h3 class="fs-6 wishlist-title mb-3">
          Resume
        </h3>
        <p class="product-wishlist mud-h mb-2 pb-0">Qty: <span class="float-end fw-bold">{{ products.length }} product/s</span></p>
        <p class="product-wishlist mud-h mb-2 pb-0">Total of amount: <span class="float-end fw-bold mud-logo-subtext">{{ priceFormat(totalAmount()) }}</span></p>
        <h3 class="fs-6 wishlist-title mb-3 mt-4">
          I want my wishlist
        </h3>
        <input
          type="email"
          name="email"
          class="form-control form-control-dark text-bg-white brand-finder mb-3"
          placeholder="Your email address..."
          aria-label="email"
          v-model="email"
        />
        <label class="mud-h mb-4">
          <div class="d-block float-start">  
            <input class="form-check-input" type="checkbox" />
          </div>
          <div class="mud-conditions">
            <span class="disclaimer">I have read and accepted all the conditions detailed in the <router-link :to="{name: 'page', params: {slug: 'privacy-policy'}}" class="mud-logo-subtext" >privacy policy</router-link> section</span>
          </div>
        </label>  
        <button @click="sendEmail(email)" type="submit" class="mud-button-second">Send me the wishlist</button>
      </div>

    </div>
  </div>
  <footer-section />
</template>